<template>
  <div class="head-container chartHeader">
    <!-- 搜索 -->
    <el-select
        v-model="query.symbol"
        placeholder="品种"
        style="width: 150px;"
        class="filter-item"
        filterable
        @keyup.enter.native="toQuery"
    >
      <el-option
          v-for="(item,index) in symbolList"
          :key="index"
          :label="item"
          :value="item"
      ></el-option>
    </el-select>
    <span class="filter-item">统计时间 (≤12h):  </span>
    <el-date-picker
        class="filter-item"
        type="datetime"
        v-model="query.startTime"
        placeholder="开始">
    </el-date-picker>
    <el-date-picker
        class="filter-item"
        type="datetime"
        v-model="query.endTime"
        placeholder="结束">
    </el-date-picker>
    <el-button
        v-if="checkPermission(['ADMIN','SPREAD_TREND_CHART_SELECT'])"
        class="filter-item btn frist"
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="toQuery"
    >查询</el-button>
    <el-button
            type="primary"
            class="filter-item btn"
            size="mini"
            icon="el-icon-close"
            @click="clear">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { getSymbols } from '@/api/stat/spread' // 权限判断函数
export default {
  name: 'chartHeader',
  props: {
    query: {
      type: Object,
      required: true,
      default: {
        periodType: 1
      }
    }
  },
  created() {
    this.getSymbolsList();
  },
  data(){
    return {
      loading:false,
      symbolList: [],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      if(!this.query.symbol){
        this.$message({
          message: '请输入品种',
        })
        return;
      }
      if(!this.query.startTime){
        this.$message({
          message: '请输入开始时间',
        })
        return;
      }
      if(!this.query.endTime){
        this.$message({
          message: '请输入结束时间',
        })
        return;
      }
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    getSymbolsList(){
      getSymbols().then((res) => {
        this.symbolList = res;
      })
    }
  }
}
</script>
<style lang="less" scoped>
.chartHeader {
  .btn {
    //margin-bottom: 0!important;
    //&.frist {
    //  margin-left: 5px;
    //}
  }
}
</style>
